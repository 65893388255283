import React from "react";

import Genetable from "../../../layouts/Genetable/Genetable";
import { Link } from "react-router-dom";

function Documents() {
    const documents = [
        {
            documentName: "Aadhaar",
            studentId: "S12345",
            status: "Uploaded",
            uploadedDate: "23/10/2024",
        },
        {
            documentName: "Birth Certificate",
            studentId: "S12346",
            status: "Pending",
            uploadedDate: "25/10/2024",
        },
        {
            documentName: "10th Certificate",
            studentId: "S12347",
            status: "Uploaded",
            uploadedDate: "27/10/2024",
        },
        // ... additional documents can be added here
    ];

    const column_table = [
        { display_name: 'Document Name', column: 'documentName' },
        { display_name: 'Student ID', column: 'studentId' },
        { display_name: 'Status', column: 'status' },
        { display_name: 'Uploaded Date', column: 'uploadedDate' }
    ];

    return (
        <div className="container-fluid py-6">
            <div className="d-flex justify-content-between align-items-center mb-2 px-4">
                <h2 className="h2 font-weight-bolder text-white mb-0">Documents</h2>
                <Link to="/adm/Master/Student/AddDocuments">
                    <button className="btn btn-light text-dark">
                        Add New
                    </button>
                </Link>
            </div>

            <Genetable
                data={documents}
                filterEnabled={true}
                filterDateColumn={false}
                column={column_table}
                Title={"Documents"}
            />
        </div>
    );
}

export default Documents;
