import React, { useState } from "react";
import Genetable from "../../../layouts/Genetable/Genetable";
import { Link } from "react-router-dom";

function VehicleEntry() {
   

    // Sample data for the table
    const vehicleEntries = [
        {
            vehicleNumber: "TN01ZC5678",
            capacity: "20",
            vehicleType: "Mini Bus",
          
            vehicleMake: "Ford",
            vehicleModel: "Transit",
            registrationNo: "REG12345",
            status: "Active",
            ownership: "Owned",
            contractorName: "",
            contractStartDate: "",
            contractEndDate: "",
            documentType: "Insurance",
            uploadFile: "insurance_doc.pdf",
        },
        {
            vehicleNumber: "TN02XY1234",
            capacity: "50",
            vehicleType: "Coach",
            
            vehicleMake: "Volvo",
            vehicleModel: "B11R",
            registrationNo: "REG67890",
            status: "Inactive",
            ownership: "Contracted",
            contractorName: "John Doe",
            contractStartDate: "2023-01-01",
            contractEndDate: "2023-12-31",
        },
    ];
    

    const column_table = [
        { display_name: "Vehicle Number", column: "vehicleNumber" },
        { display_name: "Capacity", column: "capacity" },
        { display_name: "Vehicle Type", column: "vehicleType" },
        { display_name: "Ownership", column: "ownership" },
        { display_name: "Vehicle Make", column: "vehicleMake" },
        { display_name: "Vehicle Model", column: "vehicleModel" },
        { display_name: "Registration No", column: "registrationNo" },
        { display_name: "Status", column: "status" },
        { display_name: "Contractor Name", column: "contractorName" },
        { display_name: "Contract Start Date", column: "contractStartDate" },
        { display_name: "Contract End Date", column: "contractEndDate" },
        ,
    {
      display_name: "Document Type",
      column: "documentType",
    },
    {
      display_name: "Uploaded File",
      column: "uploadFile",
    }
    ];
    

    return (
        <div className="container-fluid py-6">
            <div className="d-flex justify-content-between align-items-center mb-2 px-4">
                <h2 className="h2 font-weight-bolder text-white mb-0">Vehicle Management</h2>
                <Link to="/adm/Master/Transport/AddVehicleEntry">
                <button className="btn btn-light text-dark">
                    Add New
                </button>
                </Link>
            </div>

            {/* Display the vehicle entries in a table format */}
            <Genetable
                data={vehicleEntries}
                filterEnabled={false}
                column={column_table}
                Title={"Vehicle Table"}
            />

           
        </div>
    );
}

export default VehicleEntry;
