import React, { useState } from "react";
import "./AddDocuments.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const AddDocument = () => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [input, setInput] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [studentDetails, setStudentDetails] = useState(null);

  // Mock student data
  const studentsData = [
    {
      id: "S12345",
      name: "John Doe",
      educationalType: "Undergraduate",
      degree: "Bachelor's",
      department: "Computer Science",
      academicYear: "2023-2024",
      year: "2nd Year",
      section: "A",
    },
    {
      id: "S12346",
      name: "Jane Smith",
      educationalType: "Postgraduate",
      degree: "Master's",
      department: "Mechanical",
      academicYear: "2022-2023",
      year: "1st Year",
      section: "B",
    },
    {
      id: "S12347",
      name: "Jack White",
      educationalType: "Undergraduate",
      degree: "Bachelor's",
      department: "Electrical",
      academicYear: "2023-2024",
      year: "3rd Year",
      section: "C",
    },
    // Add more student records here...
  ];

  // Filter students based on input
  const handleSearchInputChange = (value) => {
    setInput(value);

    if (value.trim() === "") {
      setFilteredStudents([]);
      return;
    }

    // Filter students based on ID or Name (case insensitive)
    const matches = studentsData.filter(
      (student) =>
        student.id.toLowerCase().includes(value.toLowerCase()) ||
        student.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredStudents(matches);
  };

  // Handle selecting a student from the dropdown
  const handleStudentSelect = (student) => {
    setInput(student.name); // Update input to the selected student's name
    setStudentDetails(student); // Set selected student details
    setFilteredStudents([]); // Clear the dropdown
  };

  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fieldName]: file,
    }));
  };

  const handleFileRemove = (fieldName) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[fieldName];
      return updatedFiles;
    });
  };

  const renderUploadedFileName = (fieldName) => {
    const file = uploadedFiles[fieldName];
    return file ? (
      <div className="d-flex align-items-center">
        <p className="text-success fw-bold m-0 me-2">{file.name}</p>
        <button
          type="button"
          className="btn btn-sm btn-danger p-1"
          onClick={() => handleFileRemove(fieldName)}
        >
          &times;
        </button>
      </div>
    ) : (
      <p className="text-muted">No file uploaded</p>
    );
  };

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-header bg-white">
          <h4 className="text-start">Student Document Upload</h4>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="search" className="form-label">
                Search by Student ID or Name
              </label>
              <div className="dropdown">
                <input
                  type="text"
                  id="search"
                  className="form-control dropdown-toggle"
                  placeholder="Enter Student ID or Name"
                  value={input}
                  onChange={(e) => handleSearchInputChange(e.target.value)}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                {filteredStudents.length > 0 && (
                  <ul className="dropdown-menu show" style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {filteredStudents.map((student) => (
                      <li
                        key={student.id}
                        className="dropdown-item"
                        onClick={() => handleStudentSelect(student)}
                      >
                        {student.name} ({student.id})
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          {studentDetails && (
            <>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Student ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.id}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.name}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Educational Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.educationalType}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Degree</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.degree}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Department</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.department}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Academic Year</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.academicYear}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Year</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.year}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Section</label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentDetails.section}
                    readOnly
                  />
                </div>
              </div>

              <form>
                <div className="row">
                  {[
                    "Aadhaar",
                    "Birth Certificate",
                    "10th Certificate",
                    "12th Certificate",
                    "Transfer Certificate (TC)",
                    "Graduation Certificate (Optional)",
                    "Fees Receipt",
                    "Scholarship Documents",
                    "Income Certificate",
                    "Native Certificate",
                    "Caste Certificate",
                    "Disability Certificate",
                    "Migration Certificate",
                  ].map((documentName, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                      <label className="form-label">{documentName}</label>
                      <div className="drag-drop-zone">
                        <div className="drag-drop-content">
                          <i className="bi bi-cloud-arrow-up fs-2"></i>
                          <p className="m-0 fw-bold">Upload {documentName}</p>
                          <p className="text-muted">Drag and drop a file here</p>
                        </div>
                        <input
                          type="file"
                          className="file-input"
                          onChange={(e) => handleFileChange(e, documentName)}
                        />
                      </div>
                      {renderUploadedFileName(documentName)}
                    </div>
                  ))}
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary me-2">
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-secondary"
                    onClick={() => {
                      setUploadedFiles({});
                      setInput("");
                      setStudentDetails(null);
                    }}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddDocument;
