import React, { useState } from "react";
import EducationalDocuments from "./Details/EducationalDocuments";
import PersonalDocuments from "./Details/PersonalDocuments";
import ExperienceDocuments from "./Details/ExperienceDocuments";


const StaffDocumentUpload = () => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [step, setStep] = useState(1);

  const handleFileChange = (event, fieldName) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fieldName]: files, // Save the array of files
    }));
  };
  
  const handleFileRemove = (fieldName, fileName) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      if (updatedFiles[fieldName]) {
        updatedFiles[fieldName] = updatedFiles[fieldName].filter(
          (file) => file.name !== fileName
        );
      }
      return updatedFiles;
    });
  };
  

  const renderUploadedFileName = (fieldName) => {
    const files = uploadedFiles[fieldName];
    return files && files.length > 0 ? (
      <ul className="list-unstyled">
        {files.map((file, index) => (
          <li key={index} className="d-flex align-items-center">
            <p className="text-success fw-bold m-0 me-2">{file.name}</p>
            <button
              type="button"
              className="btn btn-sm btn-danger p-1"
              onClick={() => handleFileRemove(fieldName, file.name)}
            >
              &times;
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-muted">No files uploaded</p>
    );
  };
  
  const handleNext = () => setStep((prev) => prev + 1);
  const handlePrev = () => setStep((prev) => prev - 1);

  const handleSubmit = () => {
    console.log("Uploaded Files:", uploadedFiles);
  };

  const steps = [
    <PersonalDocuments
      formData={uploadedFiles}
      handleFileChange={handleFileChange}
      renderUploadedFileName={renderUploadedFileName}
    />,
    <EducationalDocuments
      formData={uploadedFiles}
      handleFileChange={handleFileChange}
      renderUploadedFileName={renderUploadedFileName}
    />,
    <ExperienceDocuments
      formData={uploadedFiles}
      handleFileChange={handleFileChange}
      renderUploadedFileName={renderUploadedFileName}
    />,
  ];

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-header bg-primary text-white">
          <h2 className="text-center text-white">Staff Document Upload</h2>
        </div>
        <div className="card-body">
          {steps[step - 1]}
          <div className="d-flex justify-content-between mt-4">
            {step > 1 && (
              <button className="btn btn-secondary" onClick={handlePrev}>
                Previous
              </button>
            )}
            {step < steps.length ? (
              <button className="btn btn-primary" onClick={handleNext}>
                Next
              </button>
            ) : (
              <button className="btn btn-success" onClick={handleSubmit}>
                Submit Documents
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDocumentUpload;
