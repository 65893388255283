import React, { useState } from "react";
import '../../AddRegistration/AddRegistration.css';

const ExperienceDetails = ({ formData, setFormData }) => {
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");

 
  const handleChange = (id, field, value) => {
    const updatedExperience = formData.experience.map((exp) =>
      exp.id === id ? { ...exp, [field]: value } : exp
    );
    setFormData({ ...formData, experience: updatedExperience });
  };


  const handleAddTag = (e) => {
    if (e.key === "Enter" && input.trim()) {
      e.preventDefault();
      if (!tags.includes(input.trim())) {
        setTags([...tags, input.trim()]);
         setInput("");
      }
    }
  };

  
  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  // Add a new experience row
  const addRow = () => {
    const newExperience = {
      id: Date.now(),
      organisationName: "",
      fromYear: "",
      toYear: "",
      designation: "",
    };

    setFormData({
      ...formData,
      experience: [...formData.experience, newExperience],
    });
  };


  const removeRow = (id) => {
    const updatedExperience = formData.experience.filter((exp) => exp.id !== id);
    setFormData({ ...formData, experience: updatedExperience });
  };

  const experience = formData.experience || [];

  return (
    <div className="form-scroll-container">
      <div className="experience-details">
        <h3 className="text-center">Experience Details</h3>
        <table className="table table-bordered language-table">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Organisation Name</th>
              <th scope="col">From Year</th>
              <th scope="col">To Year</th>
              <th scope="col">Designation</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {experience.map((exp, index) => (
              <tr key={exp.id}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={exp.organisationName}
                    onChange={(e) =>
                      handleChange(exp.id, "organisationName", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={exp.fromYear}
                    onChange={(e) =>
                      handleChange(exp.id, "fromYear", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={exp.toYear}
                    onChange={(e) =>
                      handleChange(exp.id, "toYear", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={exp.designation}
                    onChange={(e) =>
                      handleChange(exp.id, "designation", e.target.value)
                    }
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeRow(exp.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn btn-primary" onClick={addRow}>
          Add Experience
        </button>

        <div className="technical-skills">
          <h5>Software/Technical Skills</h5>
          <input
            type="text"
            placeholder="Add a skill and press Enter"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleAddTag}
            className="form-control mb-4 skill-input"
          />
          <div className="tags-input">
            {tags.map((tag, index) => (
              <div key={index} className="tag">
                {tag}
                <button
                  type="button"
                  className="btn-close ms-2"
                  onClick={() => handleRemoveTag(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceDetails;
