import React, { useState } from "react";
import '../../AddRegistration/AddRegistration.css'

const PersonalDetails = ({ formData, setFormData }) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState("");
  const [isAddressSame, setIsAddressSame] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

   
    if (name.startsWith("temporaryAddress.") || name.startsWith("permanentAddress.")) {
      const [addressType, field] = name.split(".");
      setFormData({
        ...formData,
        [addressType]: {
          ...formData[addressType],
          [field]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, photo: file });
    setProfilePhoto(URL.createObjectURL(file)); 
  };
 
  const handleAddLanguage = () => {
    if (newLanguage.trim() !== "") {
      setLanguages([...languages, newLanguage.trim()]);
      setNewLanguage("");
    }
  };
  
  const handleRemoveLanguage = (index) => {
    const updatedLanguages = languages.filter((_, i) => i !== index);
    setLanguages(updatedLanguages);
  };

  const handleAddressCheck = (isSame) => {
    setIsAddressSame(isSame);
    if (isSame) {
      // Copy temporary address to permanent address
      setFormData((prevData) => ({
        ...prevData,
        permanentAddress: { ...prevData.temporaryAddress },
      }));
    }
  };
  return (
 <div className="form-scroll-container">
 <div className="container-fluid py-4">
      <div className="row">
        <div className="col-md-12 mx-auto">


          <div className="mb-3 d-flex justify-content-between align-items-center">
            <h3 >Personal Details</h3>
            {profilePhoto ? (
              <img
                src={profilePhoto}
                alt="Profile"

                style={{ width: "80px", height: "100px", objectFit: "cover" }}
              />
            ) : (
              <div
                className=" bg-light d-flex align-items-center justify-content-center"
                style={{ width: "100px", height: "100px" }}
              >
                <span className="text-muted">No Photo</span>
              </div>
            )}
          </div>
          <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="photo" className="form-label">
              Upload Photo
            </label>
            <input
              type="file"
              className="form-control"
              id="photo"
              name="photo"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
            <div className="col-md-6 mb-3">
            <div className="mb-3">
        <label htmlFor="title" className="form-label">Title</label>
        <select id="title" name="title" className="form-control" value={formData.title} onChange={handleChange}>
          <option value="">Select Title</option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
        </select>
      </div>
            </div>
          </div>
          
          <div className="row">
          
            <div className="col-md-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="dob" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="dateOfJoining" className="form-label">
                Date of Joining
              </label>
              <input
                type="date"
                className="form-control"
                id="dateOfJoining"
                name="dateOfJoining"
                value={formData.dateOfJoining}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
   <div className="col-md-6 mb-3">
     <label htmlFor="gender" className="form-label">Gender</label>
    <select
      className="form-control custom-select"
      id="gender"
      name="gender"
      value={formData.gender}
      onChange={handleChange}
    >
      <option value="">Select Gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </select>
  </div>
  <div className="col-md-6 mb-3">
              <label htmlFor="age" className="form-label">
               Age
              </label>
              <input
                type="number"
                className="form-control"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </div>
</div>

          {/* More Fields */}
          <div className="row">
            {/* Father's and Mother's Name */}
            <div className="col-md-6 mb-3">
              <label htmlFor="fatherName" className="form-label">
                Father's Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fatherName"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="motherName" className="form-label">
                Mother's Name
              </label>
              <input
                type="text"
                className="form-control"
                id="motherName"
                name="motherName"
                value={formData.motherName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
   
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Number
              </label>
              <input
                type="Number"
                className="form-control"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
   
   <label htmlFor="alternateNumber" className="form-label">
     AlternateMobile Number
   </label>
   <input
     type="Number"
     className="form-control"
     id="alternateNumber"
     name="alternateNumber"
     value={formData.alternateNumber}
     onChange={handleChange}
   />
 </div>
 <div className="col-md-6 mb-3">
              <label htmlFor="mailId" className="form-label">
               Mail Id
              </label>
              <input
                type="text"
                className="form-control"
                id="mailId"
                name="mailId"
                value={formData.mailId}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="alternateMailId" className="form-label">
              Alternate Mail Id
              </label>
              <input
                type="text"
                className="form-control"
                id="alternateMailId"
                name="alternateMailId"
                value={formData.alternateMailId}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
            <div className="mb-3">
        <label htmlFor="maritalStatus" className="form-label">Marraige Status</label>
        <select id="maritalStatus" name="maritalStatus" className="form-control" value={formData.maritalStatus} onChange={handleChange}>
          <option value="">Marraige status</option>
          <option value="Mr">Married</option>
          <option value="Mrs">UnMarried</option>
          
        </select>
      </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="spouseName" className="form-label">
              Spouse Name
              </label>
              <input
                type="text"
                className="form-control"
                id="spouseName"
                name="spouseName"
                value={formData.spouseName}
                onChange={handleChange}
              />
            </div>  
            <div className="col-md-6 mb-3">
              <label htmlFor="nationality" className="form-label">
             Nationality
              </label>
              <input
                type="text"
                className="form-control"
                id="nationality"
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="religion" className="form-label">
             Religion
              </label>
              <input
                type="text"
                className="form-control"
                id="religion"
                name="religion"
                value={formData.religion}
                onChange={handleChange}
              />
            </div> 
            <div className="col-md-6 mb-3">
              <label htmlFor=" category" className="form-label">
            Category
              </label>
              <input
                type="text"
                className="form-control"
                id=" category"
                name=" category"
                value={formData. category}
                onChange={handleChange}
              />
            </div>      
   
            <div className="col-md-6 mb-3">
              <label htmlFor="caste" className="form-label">
          Caste
              </label>
              <input
                type="text"
                className="form-control"
                id="caste"
                name="caste"
                value={formData.caste}
                onChange={handleChange}
              />
            </div>   
            <div className="col-md-6 mb-3">
          <label htmlFor="stafftype" className="form-label">Staff Type</label>
          <select
      className="form-control custom-select"
      id="stafftype"
      name="stafftype"
      value={formData.stafftype}
      onChange={handleChange}
    >
      <option value="">Select StaffType</option>
      <option value="teaching">Teaching Staff</option>
      <option value="non-teaching">Non Teaching Staff</option>
    </select>
  </div>
  <div className="col-md-6 mb-3">
          <label htmlFor="designation" className="form-label">Designation</label>
          <select
      className="form-control custom-select"
      id="designation"
      name="designation"
      value={formData.designation}
      onChange={handleChange}
    >
      <option value="">Select Designation</option>
      <option value="HOD">HOD</option>
      <option value="Staff">Staff</option>
    </select>
  </div>
  <div className="col-md-6 mb-3">
          <label htmlFor="departmenttobehandled" className="form-label">Department To Be Handled</label>
          <select
      className="form-control custom-select"
      id="departmenttobehandled"
      name="departmenttobehandled"
      value={formData.departmenttobehandled}
      onChange={handleChange}
    >
      <option value="">Select Department</option>
      <option value="CSE">CSE</option>
      <option value="IT">IT</option>
    </select>
  </div>
   
<div className="col-md-6">
  <label className="form-label">Languages Known</label>
  {/* <div className="d-flex align-items-center mt- -3"> */}
    <input
      type="text"
      className="form-control me-2 language-input"
      value={newLanguage}
      onChange={(e) => setNewLanguage(e.target.value)}
      placeholder="e.g., Tamil"
    />
    <button
      type="button"
      className="btn btn-success custom-btn mt-3"
      onClick={handleAddLanguage}
    >
      Add
    </button>
  </div>
  {languages.length > 0 && (
    <table className="table table-bordered language-table ">
      <thead>
        <tr>
          <th scope="col">Sno</th>
          <th scope="col">Language</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {languages.map((language, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{language}</td>
            <td>
              <button
                type="button"
                className="btn btn-danger btn-sm mt-3"
                onClick={() => handleRemoveLanguage(index)}
              >
                Remove
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>




          {/* </div> */}
<div className="row">

  <div className="col-md-12 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Address Line 1"
      name="temporaryAddress.line1"
      value={formData.temporaryAddress.line1}
      onChange={handleChange}
    />
  </div>
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="City"
      name="temporaryAddress.city"
      value={formData.temporaryAddress.city}
      onChange={handleChange}
    />
  </div>
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="State"
      name="temporaryAddress.state"
      value={formData.temporaryAddress.state}
      onChange={handleChange}
    />
  </div>
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Pincode"
      name="temporaryAddress.pincode"
      value={formData.temporaryAddress.pincode}
      onChange={handleChange}
    />
  </div>
</div>

{/* Radio Buttons for Address Same/Different */}
<div className="mb-3 d-flex align-items-center">
  <label className="form-label me-3">Is the Permanent Address the same as the Temporary Address?</label>
  <div className="form-check form-check-inline">
    <input
      type="radio"
      className="form-check-input"
      id="addressSame"
      name="addressCheck"
      value="yes"
      checked={isAddressSame}
      onChange={() => handleAddressCheck(true)}
    />
    <label className="form-check-label" htmlFor="addressSame">
      Yes
    </label>
  </div>
  <div className="form-check form-check-inline">
    <input
      type="radio"
      className="form-check-input"
      id="addressDifferent"
      name="addressCheck"
      value="no"
      checked={!isAddressSame}
      onChange={() => handleAddressCheck(false)}
    />
    <label className="form-check-label" htmlFor="addressDifferent">
      No
    </label>
  </div>
</div>

{/* Permanent Address Fields */}
<div className="row">
 
  <div className="col-md-12 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Address Line 1"
      name="permanentAddress.line1"
      value={isAddressSame ? formData.temporaryAddress.line1 : formData.permanentAddress.line1}
      onChange={handleChange}
      readOnly={isAddressSame}
    />
  </div>
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="City"
      name="permanentAddress.city"
      value={isAddressSame ? formData.temporaryAddress.city : formData.permanentAddress.city}
      onChange={handleChange}
      readOnly={isAddressSame}
    />
  </div>

  
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="State"
      name="permanentAddress.state"
      value={isAddressSame ? formData.temporaryAddress.state : formData.permanentAddress.state}
      onChange={handleChange}
      readOnly={isAddressSame}
    />
  </div>
  <div className="col-md-4 mb-2">
    <input
      type="text"
      className="form-control"
      placeholder="Pincode"
      name="permanentAddress.pincode"
      value={isAddressSame ? formData.temporaryAddress.pincode : formData.permanentAddress.pincode}
      onChange={handleChange}
      readOnly={isAddressSame}
    />
  </div>

  </div>
  <div className=" mt-4">
            <label htmlFor="bloodGroup" className="form-label">
              Blood Group
            </label>
            <select
              className="form-control"
              id="bloodGroup"
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
            >
              <option value="">Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="B+">B+</option>
              <option value="O+">O+</option>
              <option value="AB+">AB+</option>
              <option value="A-">A-</option>
              <option value="B-">B-</option>
              <option value="O-">O-</option>
              <option value="AB-">AB-</option>
            </select>
          </div>
   
 
          {/* Additional Fields */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="guardian" className="form-label">
                Guardian Name
              </label>
              <input
                type="text"
                className="form-control"
                id="guardian"
                name="guardian"
                value={formData.guardian}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="guardianContact" className="form-label">
                Guardian Contact
              </label>
              <input
                type="text"
                className="form-control"
                id="guardianContact"
                name="guardianContact"
                value={formData.guardianContact}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>


   
  );
};

export default PersonalDetails;
