import React, { useState } from "react";

const AddPromotion = () => {
  const [operationType, setOperationType] = useState(""); // 'transfer' or 'promotion'
  const [formData, setFormData] = useState({
    educationType: "",
    degree: "",
    department: "",
    semester: "",
    section: "",
  });
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [transferData, setTransferData] = useState({
    transferType: "",
    newDepartment: "",
    newCollege: "",
  });
  const [promotionData, setPromotionData] = useState({
    newSemester: "",
    newSection: "",
  });
  const [selectAll, setSelectAll] = useState(false);

  // Handlers for filters
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOperationTypeChange = (e) => {
    setOperationType(e.target.value);
    setSelectedStudents([]);
    setSelectAll(false);
  };

  const handleTransferChange = (e) => {
    setTransferData({ ...transferData, [e.target.name]: e.target.value });
  };

  const handlePromotionChange = (e) => {
    setPromotionData({ ...promotionData, [e.target.name]: e.target.value });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const dummyStudents = [
      { student_id: 1, name: "John Doe", roll_no: "101" },
      { student_id: 2, name: "Jane Smith", roll_no: "102" },
      { student_id: 3, name: "Samuel Jackson", roll_no: "103" },
    ];
    setStudents(dummyStudents);
    setSelectedStudents([]);
    setSelectAll(false);
  };

  const handleStudentSelect = (student_id) => {
    setSelectedStudents((prev) =>
      prev.includes(student_id)
        ? prev.filter((id) => id !== student_id)
        : [...prev, student_id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map((student) => student.student_id));
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (operationType === "transfer") {
      console.log("Transferred Students:", selectedStudents);
      console.log("Transfer Type:", transferData.transferType);
      console.log(
        transferData.transferType === "Inter-Department"
          ? "New Department: " + transferData.newDepartment
          : "New College: " + transferData.newCollege
      );
      setTransferData({ transferType: "", newDepartment: "", newCollege: "" });
    } else {
      console.log("Promoted Students:", selectedStudents);
      console.log("New Semester:", promotionData.newSemester);
      console.log("New Section:", promotionData.newSection);
      setPromotionData({ newSemester: "", newSection: "" });
    }
    setSelectedStudents([]);
    setSelectAll(false);
  };

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-header d-flex justify-content-between align-items-center text-white">
              <h4 className="mb-0">Student Promotion / Transfer</h4>
            </div>
            <div className="card-body">
              {/* Operation Type Selector */}
              <div className="mb-4">
                <label htmlFor="operationType" className="form-label">
                  Select Operation
                </label>
                <select
                  className="form-select"
                  id="operationType"
                  value={operationType}
                  onChange={handleOperationTypeChange}
                >
                  <option value="">Select</option>
                  <option value="transfer">Student Transfer</option>
                  <option value="promotion">Student Promotion</option>
                </select>
              </div>

              <form onSubmit={handleFilter} className="mb-4">
                <h5>Filter Students</h5>
                <div className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="educationType" className="form-label">
                      Education Type
                    </label>
                    <select
                      className="form-select"
                      id="educationType"
                      name="educationType"
                      value={formData.educationType}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="UG">Undergraduate</option>
                      <option value="PG">Postgraduate</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="degree" className="form-label">
                      Degree
                    </label>
                    <select
                      className="form-select"
                      id="degree"
                      name="degree"
                      value={formData.degree}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="B.Sc">B.Sc</option>
                      <option value="B.Com">B.Com</option>
                      <option value="B.Tech">B.Tech</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="department" className="form-label">
                      Department
                    </label>
                    <select
                      className="form-select"
                      id="department"
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="CSE">CSE</option>
                      <option value="ECE">ECE</option>
                      <option value="EEE">EEE</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="semester" className="form-label">
                      Semester
                    </label>
                    <select
                      className="form-select"
                      id="semester"
                      name="semester"
                      value={formData.semester}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="section" className="form-label">
                      Section
                    </label>
                    <select
                      className="form-select"
                      id="section"
                      name="section"
                      value={formData.section}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                  Fetch Students
                </button>
              </form>


              {/* Students Table */}
              {students.length > 0 && (
                <div>
                  <h5>Students List</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectAll}
                          />
                        </th>
                        <th>Student ID</th>
                        <th>Name</th>
                        <th>Roll Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((student) => (
                        <tr key={student.student_id}>
                          <td>
                            <input
                              type="checkbox"
                              value={student.student_id}
                              onChange={() =>
                                handleStudentSelect(student.student_id)
                              }
                              checked={selectedStudents.includes(
                                student.student_id
                              )}
                            />
                          </td>
                          <td>{student.student_id}</td>
                          <td>{student.name}</td>
                          <td>{student.roll_no}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Dynamic Transfer/Promotion Form */}
              {selectedStudents.length > 0 && (
                <form onSubmit={handleSubmit} className="mt-4">
                  <h5>{operationType === "transfer" ? "Transfer Details" : "Promotion Details"}</h5>
                  <div className="row g-3">
                    {operationType === "transfer" && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="transferType" className="form-label">
                            Transfer Type
                          </label>
                          <select
                            className="form-select"
                            id="transferType"
                            name="transferType"
                            value={transferData.transferType}
                            onChange={handleTransferChange}
                          >
                            <option value="">Select</option>
                            <option value="Inter-Department">Inter-Department</option>
                            <option value="Inter-College">Inter-College</option>
                          </select>
                        </div>
                        {transferData.transferType === "Inter-Department" && (
                           <div className="col-md-6">
                           <label htmlFor="newDepartment" className="form-label">
                             New Department
                           </label>
                           <select
                             className="form-select"
                             id="newDepartment"
                             name="newDepartment"
                             value={transferData.newDepartment}
                             onChange={handleTransferChange}
                           >
                             <option value="">Select</option>
                             <option value="CSE">CSE</option>
                             <option value="ECE">ECE</option>
                             <option value="EEE">EEE</option>
                           </select>
                         </div>
                        )}
                        {transferData.transferType === "Inter-College" && (
                          <div className="col-md-6">
                            <label htmlFor="newCollege" className="form-label">
                              New College
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="newCollege"
                              name="newCollege"
                              value={transferData.newCollege}
                              onChange={handleTransferChange}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {operationType === "promotion" && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="newSemester" className="form-label">
                            New Semester
                          </label>
                          <select
                            className="form-select"
                            id="newSemester"
                            name="newSemester"
                            value={promotionData.newSemester}
                            onChange={handlePromotionChange}
                          >
                            <option value="">Select</option>
                            <option value="1">1st</option>
                            <option value="2">2nd</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="newSection" className="form-label">
                            New Section
                          </label>
                          <select
                            className="form-select"
                            id="newSection"
                            name="newSection"
                            value={promotionData.newSection}
                            onChange={handlePromotionChange}
                          >
                            <option value="">Select</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                  <button type="submit" className="btn btn-success mt-3">
                    {operationType === "transfer" ? "Transfer Students" : "Promote Students"}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPromotion;
