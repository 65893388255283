import React, { useState } from "react";
import PersonalDetails from "./Details/PersonalDetails";
import EducationalDetails from "./Details/EducationalDetails";
import ExperienceDetails from "./Details/ExperienceDetails";
import BankDetails from "./Details/BankDetails";

import PublicationAndSubjects from "./Details/PublicationAndSubjects"
const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(0);

 
  const [formData, setFormData] = useState({
  
    photo: null,
    title: "",
    firstName: "",
    lastName: "",
    dob: "",
    dateOfJoining: "",
    fatherName: "",
    motherName: "",
    gender: "",
    mobileNumber: "",
    alternateNumber: "",
    mailId: "",
    alternateMailId: "",
    age: "",
    maritalStatus: "",
    spouseName: "",
    nationality: "",
    religion: "",
    category: "",
    caste: "",
    languagesKnown: "",
    bloodGroup: "",
    temporaryAddress: { addressLine1: "", city: "", state: "", pincode: "" },
    permanentAddress: { addressLine1: "", city: "", state: "", pincode: "" },
    guardian: "",
    guardianContact: "",

    // Educational Details
    qualifications: [{qualificationName: "10th",schoolName: "",board:"",degree:"",department:"",percentage:"",yop:""},
     ],
    
    careerGap: { from: "", to: "" },

    // Experience Details
    experience: [{ orgName: "", fromYear: "", toYear: "", domain: "", ctc: "" }],
    

    // Bank Details
    accountName: "",
    accountNumber: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
    aadharNumber: "",
    panNumber: "",
  });

  const handleNext = () => setCurrentStep((prev) => prev + 1);
  const handlePrevious = () => setCurrentStep((prev) => prev - 1);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Final Form Data:", formData);
  };

  const steps = [
    <PersonalDetails formData={formData} setFormData={setFormData} />,
    <EducationalDetails formData={formData} setFormData={setFormData} />,
    <ExperienceDetails formData={formData} setFormData={setFormData} />,
    <BankDetails formData={formData} setFormData={setFormData} />,
    <PublicationAndSubjects formData={formData} setFormData={setFormData} />,
  ];

  return (
    <div className="container">
      <h2 className="h2 font-weight-bolder text-white m-4">Add Registration</h2>
      <form onSubmit={handleSubmit}>
        <div className="card p-4">
          {steps[currentStep]}

          <div className="button-group d-flex justify-content-between mt-3">
            {currentStep > 0 && (
              <button
                type="button"
                className="btn btn-light text-dark m-4"
                onClick={handlePrevious}
              >
                Previous
              </button>
            )}
            {currentStep < steps.length - 1 ? (
              <button
                type="button"
                className="btn btn-primary text-white m-4 "
                onClick={handleNext}
              >
                Next
              </button>
            ) : (
              <button type="submit" className="btn btn-primary m-4">
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default MultiStepForm;