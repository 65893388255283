import React, { useState } from "react";
import Genetable from "../../../layouts/Genetable/Genetable";
import { Link } from "react-router-dom";

function LocationSelector() {


  // Sample data for the table
  const locations = [
    {
      locationName: "Location A",
      startPoint: "Point 1",
      endPoint: "Point 5",
      stages: "Point 2, Point 3, Point 4",
      description: "Test location route",
      status: "Active",
    },
  ];
  

  const column_table = [
    { display_name: "Location Name", column: "locationName" },
    { display_name: "Start Point", column: "startPoint" },
    { display_name: "End Point", column: "endPoint" },
    { display_name: "Stages", column: "stages" },
    { display_name: "Description", column: "description" },
    { display_name: "Status", column: "status" },
  ];

  return (
    <div className="container-fluid py-6">
      <div className="d-flex justify-content-between align-items-center mb-2 px-4">
        <h2 className="h2 font-weight-bolder text-white mb-0">Location Management</h2>
        <Link to="/adm/Master/Transport/AddLocationSelector">
        <button className="btn btn-light text-dark" >
          Add New Location
        </button>
        </Link>
      </div>

      {/* Display the locations in a table format */}
      <Genetable
        data={locations}
        filterEnabled={false}
        column={column_table}
        Title={"Location Table"}
      />

      
    </div>
  );
}

export default LocationSelector;
