import React from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../AddStaffDocument/AddStaffDocument.css'

const ExperienceDocuments = ({ formData, handleFileChange, renderUploadedFileName }) => {
  const experienceDocuments = [
    "Offer Letter",
  ];

  return (
    <div>
      <h3 className="text-center text-primary">Experience Documents</h3>
      <div className="row">
        {experienceDocuments.map((documentName, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <label className="form-label">{documentName}</label>
            <div className="drag-drop-zone">
              <div className="drag-drop-content">
                <i className="bi bi-cloud-arrow-up fs-2"></i>
                <p className="m-0 fw-bold">Upload {documentName}</p>
                <p className="text-muted">Drag and drop a file here</p>
              </div>
              <input
                type="file"
                className="file-input"
                onChange={(e) => handleFileChange(e, documentName)}
              />
            </div>
            {renderUploadedFileName(documentName)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceDocuments;
