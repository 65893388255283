import React, { useState } from "react";
import Modal from "./Modal/Modal"; // Import Modal component
import "./Genetable.css"; // Custom styles if needed for further responsive adjustments

function Genetable({ data, filterEnabled, filterDateColumn, column, Title, preview }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [filterColumn, setFilterColumn] = useState("name");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page
    const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
    const [viewData, setViewData] = useState(null); // State to store data to be displayed in the modal
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" }); // Sorting state

    const handleEdit = (authorName) => {
        console.log(`Edit ${authorName}`);
    };

    const handleView = (item) => {
        setViewData(item); // Set the data for the modal
        setModalVisible(true); // Show the modal
    };

    const handleCloseModal = () => {
        setModalVisible(false); // Close the modal
    };

    const handleSort = (columnKey) => {
        let direction = "asc";
        if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key: columnKey, direction });

        // Sort the data based on the selected column and order
        data.sort((a, b) => {
            if (a[columnKey] < b[columnKey]) {
                return direction === "asc" ? -1 : 1;
            }
            if (a[columnKey] > b[columnKey]) {
                return direction === "asc" ? 1 : -1;
            }
            return 0;
        });
    };

    const filteredData = data.filter((item) => {
        if (!filterEnabled) return true;
    
        const valueToCheck = item[filterColumn]?.toLowerCase() || "";
        const matchesSearchTerm = valueToCheck.includes(searchTerm.toLowerCase());
    
        let matchesDateRange = true;
    
        // If filterDateColumn is true, always apply date range filter
        if (filterDateColumn) {
            // Check if employedDate exists and is a string before splitting
            if (item.employedDate && typeof item.employedDate === "string") {
                const employedDateParts = item.employedDate.split("/");
                if (employedDateParts.length === 3) {
                    const employedDate = new Date(`${employedDateParts[2]}-${employedDateParts[1]}-${employedDateParts[0]}`);
                    const startDateObj = startDate ? new Date(startDate) : null;
                    const endDateObj = endDate ? new Date(endDate) : null;
    
                    matchesDateRange =
                        (!startDateObj || employedDate >= startDateObj) &&
                        (!endDateObj || employedDate <= endDateObj);
                } else {
                    matchesDateRange = false; // If the date format is incorrect, exclude the item
                }
            } else {
                matchesDateRange = false; // If employedDate is not a valid string, exclude the item
            }
        }
    
        return matchesSearchTerm && matchesDateRange;
    });
    
    

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage((prev) => prev - 1);
    };

    return (
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-8">
                        <div className="card-header pb-0 text-center">
                            <h6 className="my-3">{Title}</h6>
                            <div className="row mb-3 justify-content-center">
                            {filterEnabled && (
                               <>
                                    <div className="col-12 col-md-6 col-lg-3">
                                        <label htmlFor="filterColumn" className="form-label">Filter By</label>
                                        <select
                                            id="filterColumn"
                                            className="form-select"
                                            value={filterColumn}
                                            onChange={(e) => setFilterColumn(e.target.value)}
                                        >
                                            {column.map((col, index) => (
                                                <option key={index} value={col.column}>{col.display_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0">
                                        <label htmlFor="searchTerm" className="form-label">Search</label>
                                        <input
                                            id="searchTerm"
                                            type="text"
                                            placeholder={`Search by ${filterColumn}...`}
                                            className="form-control"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>

                                    {filterDateColumn && (
                                        <>
                                            <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0">
                                                <label htmlFor="startDate" className="form-label">Start Date</label>
                                                <input
                                                    id="startDate"
                                                    type="date"
                                                    className="form-control"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0">
                                                <label htmlFor="endDate" className="form-label">End Date</label>
                                                <input
                                                    id="endDate"
                                                    type="date"
                                                    className="form-control"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                             {/* Row Selection Dropdown */}
                            
                                <div className="col-12 col-md-6 col-lg-2">
                                    <label htmlFor="rowsPerPage" className="form-label">Rows per page</label>
                                    <select
                                        id="rowsPerPage"
                                        className="form-select"
                                        value={rowsPerPage}
                                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                    </select>
                                </div>
                            </div>
                           
                        </div>

                        {/* Table View */}
                        <div className="table-responsive">
                            <table className="table align-items-center mb-0 text-center">
                                <thead className="text-start">
                                    <tr>
                                        {column.map((col, index) => (
                                            <th key={index} onClick={() => handleSort(col.column)}>
                                                {col.display_name}
                                                {sortConfig.key === col.column ? (
                                                    sortConfig.direction === "asc" ? " 🔼" : " 🔽"
                                                ) : null}
                                            </th>
                                        ))}
                                        <th>Actions</th>
                                        {preview && (
                                            <th>Preview</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="text-sm text-start">
                                    {currentData.map((item, index) => (
                                        <tr key={index}>
                                            {/* Displaying each column's data */}
                                            {column.map((col, colIndex) => (
                                                <td className="ps-4" key={colIndex} title={item[col.column]}>
                                                    {typeof item[col.column] === 'string' && item[col.column]?.length > 30
                                                        ? `${item[col.column].slice(0, 30)}...`
                                                        : item[col.column]}
                                                </td>
                                            ))}


                                            {/* Action Buttons (e.g., Edit) */}
                                            <td className="ps-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleEdit(item.name)}
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            </td>

                                            {/* Conditionally Render "View" Button */}
                                            {preview && (
                                                <td className="ps-4">
                                                    <button
                                                        className="btn btn-info"
                                                        onClick={() => handleView(item)}
                                                    >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination Controls */}
                        <div className="container">
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between w-100 my-4">
                                {/* Previous Button */}
                                <button
                                    className="btn btn-secondary mb-2 mb-md-0"
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>

                                {/* Page Info */}
                                <span className="my-2 text-center text-md-start">
                                    Page {currentPage} of {totalPages}
                                </span>

                                {/* Next Button */}
                                <button
                                    className="btn btn-secondary mb-2 mb-md-0"
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Component */}
            <Modal show={modalVisible} data={viewData} onClose={handleCloseModal} />
        </div>
    );
}

export default Genetable;
