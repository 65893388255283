import React from "react";
import '../../AddRegistration/AddRegistration.css';

const EducationalDetails = ({ formData, setFormData }) => {
  const handleChange = (id, field, value) => {
    const updatedQualifications = formData.qualifications.map((q) =>
      q.id === id ? { ...q, [field]: value } : q
    );
    setFormData({ ...formData, qualifications: updatedQualifications });
  };

  const addRow = () => {
    const newQualification = {
      id: Date.now(),
      qualificationName: "UG",
      schoolName: "",
      board: "",
      percentage: "",
      yop: "",
      degree: "",
      department: "",
    };

    setFormData({
      ...formData,
      qualifications: [...formData.qualifications, newQualification],
    });
  };

  const removeRow = (id) => {
    const updatedQualifications = formData.qualifications.filter(
      (q) => q.id !== id
    );
    setFormData({ ...formData, qualifications: updatedQualifications });
  };

  const qualifications = formData.qualifications || [];

  return (
    <div className="educational-details">
      <h3 className="text-center">Educational Qualifications</h3>
      <table className="table table-bordered language-table">
        <thead>
          <tr>
            <th  scope="col">S.No</th>
            <th  scope="col">Qualification</th>
            <th  scope="col">School/Institute Name</th>
            <th  scope="col">Board</th>
            <th  scope="col">Percentage</th>
            <th  scope="col">YOP</th>
            <th  scope="col">Degree</th>
            <th  scope="col">Department</th>
            <th  scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {qualifications.map((qualification, index) => (
            <tr key={qualification.id} className="table-row">
              <td>{index + 1}</td>
              <td>
                {index === 0 || index === 1 ? (
                  <input
                    type="text"
                    className="form-control"
                    value={index === 0 ? "10th" : "12th"}
                    readOnly
                  />
                ) : (
                  <select
                    className="form-select"
                    value={qualification.qualificationName}
                    onChange={(e) =>
                      handleChange(
                        qualification.id,
                        "qualificationName",
                        e.target.value
                      )
                    }
                  >
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                  </select>
                )}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={qualification.schoolName}
                  onChange={(e) =>
                    handleChange(qualification.id, "schoolName", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={qualification.board}
                  onChange={(e) =>
                    handleChange(qualification.id, "board", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={qualification.percentage}
                  onChange={(e) =>
                    handleChange(qualification.id, "percentage", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={qualification.yop}
                  onChange={(e) =>
                    handleChange(qualification.id, "yop", e.target.value)
                  }
                />
              </td>
              <td>
                {index === 0 || index === 1 ? (
                  <input type="text" className="form-control" value="-" readOnly />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    value={qualification.degree}
                    onChange={(e) =>
                      handleChange(qualification.id, "degree", e.target.value)
                    }
                  />
                )}
              </td>
              <td>
                {index === 0 || index === 1 ? (
                  <input type="text" className="form-control" value="-" readOnly />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    value={qualification.department}
                    onChange={(e) =>
                      handleChange(qualification.id, "department", e.target.value)
                    }
                  />
                )}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeRow(qualification.id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-primary" onClick={addRow}>
        Add Qualification
      </button>
    </div>
  );
};

export default EducationalDetails;
