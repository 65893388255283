import React from "react";

const BankDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="bank-details">
      <h3 className="text-center">Bank Details</h3>
      <h5>Bank Details</h5>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="accountName" className="form-label">
            Account Name
          </label>
          <input
            type="text"
            className="form-control"
            id="accountName"
            name="accountName"
            value={formData.accountName}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="accountNumber" className="form-label">
            Account Number
          </label>
          <input
            type="text"
            className="form-control"
            id="accountNumber"
            name="accountNumber"
            value={formData.accountNumber}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="bankName" className="form-label">
            Bank Name
          </label>
          <input
            type="text"
            className="form-control"
            id="bankName"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="branchName" className="form-label">
            Branch Name
          </label>
          <input
            type="text"
            className="form-control"
            id="branchName"
            name="branchName"
            value={formData.branchName}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="ifscCode" className="form-label">
            IFSC Code
          </label>
          <input
            type="text"
            className="form-control"
            id="ifscCode"
            name="ifscCode"
            value={formData.ifscCode}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="aadharNumber" className="form-label">
            Aadhar Number
          </label>
          <input
            type="text"
            className="form-control"
            id="aadharNumber"
            name="aadharNumber"
            value={formData.aadharNumber}
            onChange={handleChange}
          />
        </div>

        <div className=" mb-3">
          <label htmlFor="panNumber" className="form-label">
            PAN Number
          </label>
          <input
            type="text"
            className="form-control"
            id="panNumber"
            name="panNumber"
            value={formData.panNumber}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
