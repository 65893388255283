import React, { useState } from "react";
import "../../../Staff/AddRegistration/AddRegistration.css";

const AddMealPlanning = () => {
    const [formData, setFormData] = useState({
        day: "",
        variety: "",
        foodType: "",
        foodCategory: "",
        selectedFoodItems: [], // Array to store selected food items
        description: "",
        status: "",
    });

    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    // Predefined dummy data for food items based on categories
    const foodItems = {
        Breakfast: ["Pancakes", "Omelette", "Cereal", "Toast"],
        Lunch: ["Grilled Chicken", "Pasta", "Vegetable Salad", "Rice & Curry","Grilled Chicken", "Pasta", "Vegetable Salad", "Rice & Curry"],
        Dinner: ["Steak", "Soup", "Sandwich", "Fried Rice"],
        Snacks: ["Bajji", "Bonda"],
        Drinks: ["Tea", "Coffee"]
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (item) => {
        setFormData((prevState) => {
            const selected = [...prevState.selectedFoodItems];
            if (selected.includes(item)) {
                return {
                    ...prevState,
                    selectedFoodItems: selected.filter((food) => food !== item),
                };
            } else {
                return {
                    ...prevState,
                    selectedFoodItems: [...selected, item],
                };
            }
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Update the search query
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
    };

    const selectedFoodItems = formData.foodCategory ? foodItems[formData.foodCategory] : [];

    // Filter food items based on search query
    const filteredFoodItems = selectedFoodItems.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="container mt-4" style={{
            maxHeight: "600px",
            overflowY: "auto",
           height:"330px"
          
        }}>
            
            <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="day" className="form-label">Select Day</label>
                            <select
                                className="form-select"
                                id="day"
                                name="day"
                                value={formData.day}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Choose...</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="foodType" className="form-label">Select Food Type</label>
                            <select
                                className="form-select"
                                id="foodType"
                                name="foodType"
                                value={formData.foodType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Choose...</option>
                                <option value="Vegetarian">Vegetarian</option>
                                <option value="Non-Vegetarian">Non-Vegetarian</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="foodCategory" className="form-label">Select Category</label>
                    <select
                        className="form-select"
                        id="foodCategory"
                        name="foodCategory"
                        value={formData.foodCategory}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Choose...</option>
                        <option value="Breakfast">Breakfast</option>
                        <option value="Lunch">Lunch</option>
                        <option value="Dinner">Dinner</option>
                        <option value="Snacks">Snacks</option>
                        <option value="Drinks">Drinks</option>
                    </select>
                </div>
                <div className="row">
                    <div className="mb-3 col-md-6">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="status" className="form-label">Status</label>
                        <select
                            className="form-select"
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Choose...</option>
                            <option value="Available">Available</option>
                            <option value="Unavailable">Unavailable</option>
                        </select>
                    </div>
                </div>

                {selectedFoodItems.length > 0 && (
                    <div className="mt-4">
                        <h5 className="text-success">Available Food Items:</h5>
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Search food items..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <div
                            
                        >
                            {filteredFoodItems.map((item, index) => (
                                <div className="form-check" key={index}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`foodItem-${index}`}
                                        value={item}
                                        onChange={() => handleCheckboxChange(item)}
                                        checked={formData.selectedFoodItems.includes(item)}
                                    />
                                    <label
                                        className="form-check-label text-dark"
                                        htmlFor={`foodItem-${index}`}
                                    >
                                        {item}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="text-center mt-4">
                    <button type="submit" onClick={handleSubmit} className="btn btn-primary px-4">Submit</button>
                </div>
            
        </div>
    );
};

export default AddMealPlanning;
