import React, { useState, useEffect } from "react";

function ContactInformation() {
  const [formData, setFormData] = useState({
    temporaryAddress: { line1: "", city: "", state: "", pincode: "" },
    permanentAddress: { line1: "", city: "", state: "", pincode: "" },
  });
  const [isAddressSame, setIsAddressSame] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [key, field] = name.split(".");
    setFormData((prev) => ({
      ...prev,
      [key]: { ...prev[key], [field]: value },
    }));
  };

  const handleAddressCheck = (value) => {
    setIsAddressSame(value);
  };

  useEffect(() => {
    if (isAddressSame) {
      setFormData((prev) => ({
        ...prev,
        permanentAddress: { ...prev.temporaryAddress },
      }));
    }
  }, [isAddressSame, formData.temporaryAddress]);

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card">
            <div className="card-header">
              <h4>Contact Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                {/* Email Fields */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="emailAddress" className="form-control-label">
                    Email Address
                  </label>
                  <input
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    placeholder="Enter email address"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="altEmail" className="form-control-label">
                    Alternate Email Address
                  </label>
                  <input
                    id="altEmail"
                    type="email"
                    className="form-control"
                    placeholder="Enter alternate email"
                  />
                </div>

                {/* Mobile Fields */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="mobileNumber" className="form-control-label">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    type="tel"
                    className="form-control"
                    placeholder="Enter mobile number"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="altMobile" className="form-control-label">
                    Alternate Mobile Number
                  </label>
                  <input
                    id="altMobile"
                    type="tel"
                    className="form-control"
                    placeholder="Enter alternate mobile number"
                  />
                </div>

                {/* Temporary Address Fields */}
                <h5 className="mt-3">Temporary Address</h5>
                <div className="col-md-12 mb-2">
                  <label htmlFor="temporaryAddressLine1">Address Line 1</label>
                  <input
                    id="temporaryAddressLine1"
                    type="text"
                    className="form-control"
                    placeholder="Temporary Address Line 1"
                    name="temporaryAddress.line1"
                    value={formData.temporaryAddress.line1}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="temporaryCity">City</label>
                  <input
                    id="temporaryCity"
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="temporaryAddress.city"
                    value={formData.temporaryAddress.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="temporaryState">State</label>
                  <input
                    id="temporaryState"
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="temporaryAddress.state"
                    value={formData.temporaryAddress.state}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="temporaryPincode">Pincode</label>
                  <input
                    id="temporaryPincode"
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    name="temporaryAddress.pincode"
                    value={formData.temporaryAddress.pincode}
                    onChange={handleChange}
                  />
                </div>

                {/* Radio Buttons */}
                <div className="mb-3 d-flex align-items-center">
                  <label className="form-label me-3">
                    Is the Permanent Address the same as the Temporary Address?
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="addressSame"
                      name="addressCheck"
                      value="yes"
                      checked={isAddressSame}
                      onChange={() => handleAddressCheck(true)}
                    />
                    <label className="form-check-label" htmlFor="addressSame">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="addressDifferent"
                      name="addressCheck"
                      value="no"
                      checked={!isAddressSame}
                      onChange={() => handleAddressCheck(false)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="addressDifferent"
                    >
                      No
                    </label>
                  </div>
                </div>

                {/* Permanent Address Fields */}
                <h5 className="mt-3">Permanent Address</h5>
                <div className="col-md-12 mb-2">
                  <label htmlFor="permanentAddressLine1">Address Line 1</label>
                  <input
                    id="permanentAddressLine1"
                    type="text"
                    className="form-control"
                    placeholder="Permanent Address Line 1"
                    name="permanentAddress.line1"
                    value={formData.permanentAddress.line1}
                    onChange={handleChange}
                    readOnly={isAddressSame}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="permanentCity">City</label>
                  <input
                    id="permanentCity"
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="permanentAddress.city"
                    value={formData.permanentAddress.city}
                    onChange={handleChange}
                    readOnly={isAddressSame}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="permanentState">State</label>
                  <input
                    id="permanentState"
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="permanentAddress.state"
                    value={formData.permanentAddress.state}
                    onChange={handleChange}
                    readOnly={isAddressSame}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label htmlFor="permanentPincode">Pincode</label>
                  <input
                    id="permanentPincode"
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    name="permanentAddress.pincode"
                    value={formData.permanentAddress.pincode}
                    onChange={handleChange}
                    readOnly={isAddressSame}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInformation;
