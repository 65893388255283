import React, { useState } from 'react';
import '../../../Staff/AddRegistration/AddRegistration.css'

const AddFoodName = () => {
  const [formData, setFormData] = useState({
    foodType: "",
    foodCategory: "",
    foodName: "",
  });

  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && input.trim()) {
      e.preventDefault();
      if (!tags.includes(input.trim())) {
        setTags([...tags, input.trim()]);
         setInput("");
      }
    }
  };

  
  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };



  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
    <div className="row"> 
      <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="foodType" className="form-label">Select Food Type</label>
          <select
            className="form-select"
            id="foodType"
            name="foodType"
            value={formData.foodType}
            onChange={handleChange}
            required
          >
            <option value="">Choose Food Type</option>
            <option value="veg">Vegetarian</option>
            <option value="nonVeg">Non-Vegetarian</option>
          </select>
        </div>
        
      </div>
      <div className="col-md-6">
       
       <div className="mb-3">
         <label htmlFor="foodCategory" className="form-label">Select Food Category</label>
         <select
           className="form-select"
           id="foodCategory"
           name="foodCategory"
           value={formData.foodCategory}
           onChange={handleChange}
           required
         >
           <option value="">Choose Food Category</option>
           <option value="Breakfast">Breakfast</option>
           <option value="lunch">Lunch</option>
           <option value="Dinner">Dinner</option>
           <option value="Snacks">Snacks</option>
           <option value="Drinks">Drinks</option>
         </select>
       </div>
</div>
</div>
<div className="technical-skills">
          <h5>Add Food Name </h5>
          <input
            type="text"
            placeholder="Add Food Name and Press Enter"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleAddTag}
            className="form-control mb-4 skill-input"
          />
          <div className="tags-input">
            {tags.map((tag, index) => (
              <div key={index} className="tag">
                {tag}
                <button
                  type="button"
                  className="btn-close ms-2"
                  onClick={() => handleRemoveTag(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>
 <div className="row">
   
 <div className="mb-3 col-md-6">
         <label htmlFor="description" className="form-label">Description</label>
         <input
           className="form-control"
           id="description"
           name="description"
           value={formData.description}
           onChange={handleChange}
          
           placeholder="Enter description"
           required
         />
       </div>

       
       
       
       <div className="mb-3 col-md-6">
          <label htmlFor="status" className="form-label">Status</label>
          <select
            className="form-select"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
</div>     


     
       
       

     
        <div className="text-center">
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddFoodName;
