import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
        setFormErrors('');
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            setFormErrors('Email is required');
            return;
        }

        if (!validateEmail(email)) {
            setFormErrors('Invalid email format');
            return;
        }

        // Mock request to reset password
        setTimeout(() => {
            toast.success('A password reset link has been sent to your email!', {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setEmail('');
        }, 100);
    };

    return (
        <main className="main-content mt-0">
            <section>
                <div className="page-header min-vh-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                <div className="card card-plain">
                                    <div className="card-header pb-0 text-start">
                                        <h4 className="font-weight-bolder">Forgot Password</h4>
                                        <p className="mb-0">
                                            Enter your email address and we'll send you instructions to reset your password.
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3 position-relative">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="form-control form-control-lg"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    aria-label="Email"
                                                />
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        right: '15px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        color: '#6c757d',
                                                        fontSize: '1.2rem',
                                                    }}
                                                >
                                                    <FaEnvelope />
                                                </span>
                                            </div>
                                            {formErrors && (
                                                <div className="mb-2">
                                                    <span className="text-danger">{formErrors}</span>
                                                </div>
                                            )}
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                                                >
                                                    Send Reset Link
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                <div
                                    className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                                    style={{
                                        backgroundImage:
                                            "url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/forgot-password.jpg')",
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <span className="mask bg-gradient-primary opacity-6"></span>
                                    <h4 className="mt-5 text-white font-weight-bolder position-relative">
                                        "Forgot your password?"
                                    </h4>
                                    <p className="text-white position-relative">
                                        Don't worry, we've got your back. Reset it and regain access.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Toast notification container */}
            <ToastContainer />
        </main>
    );
}

export default ForgotPassword;
