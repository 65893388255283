import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../AddStaffDocument/AddStaffDocument.css"; 

const EducationalDocuments = ({ handleFileChange, renderUploadedFileName }) => {
  const [documents, setDocuments] = useState([
    { id: 1, label: "10th Public Exam Certificate" },
    { id: 2, label: "12th Public Exam Certificate" },
    { id: 3, label: "UG Degree Certificate" },
    { id: 4, label: "PG Degree Certificate" }, 
  ]);

  const addMoreDocument = () => {
    const newDocument = {
      id: documents.length + 1,
      label: `Additional Document ${documents.length - 3}`,
    };
    setDocuments([...documents, newDocument]);
  };

  const removeDocument = (id) => {
    setDocuments((prev) => prev.filter((doc) => doc.id !== id));
  };

  return (
    <div>
      <h3 className="text-center text-primary mb-4">Educational Documents</h3>
      <div className="row">
        {documents.map((document, index) => (
          <div className="col-md-6 mb-4" key={document.id}>
            <label className="form-label">{document.label}</label>
            <div className="drag-drop-zone">
              <div className="drag-drop-content">
                <i className="bi bi-cloud-arrow-up fs-2 text-primary"></i>
                <p className="m-0 fw-bold">Upload {document.label}</p>
                <p className="text-muted">Drag and drop files here</p>
              </div>
              <input
                type="file"
                className="file-input"
                onChange={(e) => handleFileChange(e, document.label)}
              />
            </div>
            {renderUploadedFileName(document.label)}
            {index > 3 && ( // Only show Remove button for additional PG documents (index > 3)
              <button
                className="btn btn-danger btn-sm mt-2"
                onClick={() => removeDocument(document.id)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={addMoreDocument}
        >
          Add More Documents
        </button>
      </div>
    </div>
  );
};

export default EducationalDocuments;
