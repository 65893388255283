import React, { useState } from "react";

function VehicleEntryForm({ onOwnershipChange, onSubmit }) {
  const [formData, setFormData] = useState({
    vehicleNumber: "",
    vehicleType: "",
    vehicleMake: "",
    vehicleModel: "",
    registrationNo: "",
    capacity: "",
    ownership: "Owned",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "ownership") {
      onOwnershipChange(value); // Notify parent about ownership change
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Vehicle Entry Form submitted with data: ", formData);
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="vehicleNumber" className="form-label">
              Vehicle Number
            </label>
            <input
              type="text"
              className="form-control"
              id="vehicleNumber"
              name="vehicleNumber"
              value={formData.vehicleNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="registrationNo" className="form-label">
              Registration No.
            </label>
            <input
              type="text"
              className="form-control"
              id="registrationNo"
              name="registrationNo"
              value={formData.registrationNo}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="vehicleType" className="form-label">
              Vehicle Type
            </label>
            <select
              className="form-select"
              id="vehicleType"
              name="vehicleType"
              value={formData.vehicleType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a vehicle type
              </option>
              <option value="Car">Car</option>
              <option value="Motorcycle">Motorcycle</option>
              <option value="Truck">Truck</option>
              <option value="SUV">SUV</option>
            </select>
          </div>
        </div>
        {/* Vehicle Make Dropdown */}
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="vehicleMake" className="form-label">
              Vehicle Make
            </label>
            <select
              className="form-select"
              id="vehicleMake"
              name="vehicleMake"
              value={formData.vehicleMake}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a vehicle make
              </option>
              <option value="Toyota">Toyota</option>
              <option value="Honda">Honda</option>
              <option value="Ford">Ford</option>
              <option value="Chevrolet">Chevrolet</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="vehicleModel" className="form-label">
              Vehicle Model
            </label>
            <select
              className="form-select"
              id="vehicleModel"
              name="vehicleModel"
              value={formData.vehicleModel}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a vehicle model
              </option>
              <option value="Corolla">Corolla</option>
              <option value="Civic">Civic</option>
              <option value="F-150">F-150</option>
              <option value="Silverado">Silverado</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="capacity" className="form-label">
              Capacity
            </label>
            <input
              type="number"
              className="form-control"
              id="capacity"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="Description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="Description"
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="ownership" className="form-label">
          Ownership
        </label>
        <select
          className="form-select"
          id="ownership"
          name="ownership"
          value={formData.ownership}
          onChange={handleChange}
        >
          <option value="Owned">Owned</option>
          <option value="Contracted">Contracted</option>
        </select>
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-primary mt-4">
          Next
        </button>
      </div>
    </form>
  );
}

function DetailsForm({ onBack, onSubmit }) {
  const [formData, setFormData] = useState({
    processName: "",
    processValue: "",
    processDate: "",
    nextDueDate: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Details Form submitted with data: ", formData);
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <div className="row">
        {/* First Column */}
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="processName" className="form-label">
              Process Name
            </label>
            <input
              type="text"
              className="form-control"
              id="processName"
              name="processName"
              value={formData.processName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="processDate" className="form-label">
              Process Date
            </label>
            <input
              type="date"
              className="form-control"
              id="processDate"
              name="processDate"
              value={formData.processDate}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Second Column */}
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="processValue" className="form-label">
              Process Value
            </label>
            <input
              type="text"
              className="form-control"
              id="processValue"
              name="processValue"
              value={formData.processValue}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="nextDueDate" className="form-label">
              Next Due Date
            </label>
            <input
              type="date"
              className="form-control"
              id="nextDueDate"
              name="nextDueDate"
              value={formData.nextDueDate}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={onBack}>
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}

function DocumentationForm({ onBack, onSubmit }) {
  const [formData, setFormData] = useState({
    documentType: "",
    documentFile: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Documentation Form submitted with data: ", formData);
    onSubmit(formData);
  };

  const handleStaticChange = (docId, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [docId]: {
        ...prevState[docId],
        [field]: value,
      },
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <table className="table">
        <thead>
          <tr>
            <th>Document Type</th>
            <th>Issued Date</th>
            <th>Expiry Date</th>
            <th>Upload Document</th>
          </tr>
        </thead>
        <tbody>
          {/* Row for Each Document */}
          {[
            { id: "vehicleRegistration", label: "Vehicle Registration" },
            { id: "insurancePolicy", label: "Insurance Policy" },
            { id: "roadPermit", label: "Road Permit" },
            {
              id: "pollutionCertificate",
              label: "Pollution Under Control (PUC)",
            },
            { id: "fitnessCertificate", label: "Fitness Certificate" },
            { id: "taxReceipt", label: "Tax Payment Receipt" },
          ].map((doc, index) => (
            <tr key={index}>
              {/* Document Type */}
              <td>{doc.label}</td>

              {/* Issued Date */}
              <td>
                <input
                  type="date"
                  className="form-control"
                  id={`issuedDate-${doc.id}`}
                  name={`issuedDate-${doc.id}`}
                  value={formData[doc.id]?.issuedDate || ""}
                  onChange={(e) =>
                    handleStaticChange(doc.id, "issuedDate", e.target.value)
                  }
                  required
                />
              </td>

              {/* Expiry Date */}
              <td>
                <input
                  type="date"
                  className="form-control"
                  id={`expiryDate-${doc.id}`}
                  name={`expiryDate-${doc.id}`}
                  value={formData[doc.id]?.expiryDate || ""}
                  onChange={(e) =>
                    handleStaticChange(doc.id, "expiryDate", e.target.value)
                  }
                  required
                />
              </td>

              {/* Upload Document */}
              <td>
                <input
                  type="file"
                  className="form-control"
                  id={`documentFile-${doc.id}`}
                  name={`documentFile-${doc.id}`}
                  onChange={(e) =>
                    handleStaticChange(
                      doc.id,
                      "documentFile",
                      e.target.files[0]
                    )
                  }
                  required
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Action Buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={onBack}>
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          Next
        </button>
      </div>
    </form>
  );
}

function ContractorDetailsForm({ onBack, onSubmit }) {
  const [formData, setFormData] = useState({
    contractorName: "",
    contactNumber: "",
    email: "",
    taxId: "",
    officeAddress: "",
    contractStartDate: "",
    contractEndDate: "",
    serviceScope: "",
    paymentTerms: "",
    emergencyContactName: "",
    emergencyContactPosition: "",
    emergencyContactInfo: "",
    authorizedRepName: "",
    authorizedRepPosition: "",
    authorizedRepContact: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Contractor Details Form submitted with data: ", formData);
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      {/* Contractor Information */}
      {/* <h4 className="mb-3">Contractor Information</h4> */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="contractorName" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            className="form-control"
            id="contractorName"
            name="contractorName"
            value={formData.contractorName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="contactNumber" className="form-label">
            Contact Number
          </label>
          <input
            type="tel"
            className="form-control"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="taxId" className="form-label">
            Tax ID / GST Number
          </label>
          <input
            type="text"
            className="form-control"
            id="taxId"
            name="taxId"
            value={formData.taxId}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* <h5 className="mt-3">Office Address</h5> */}
      <div className="col-md-12 mb-2">
        <label htmlFor="temporaryAddressLine1">Office Address Line 1</label>
        <input
          id="temporaryAddressLine1"
          type="text"
          className="form-control"
          placeholder="Office Address Line 1"
          name="temporaryAddress.line1"
          value={""}
          onChange={handleChange}
        />
      </div>
      <div className="row">
        <div className="col-md-4 mb-2">
          <label htmlFor="temporaryCity">City</label>
          <input
            id="temporaryCity"
            type="text"
            className="form-control"
            placeholder="City"
            name="temporaryAddress.city"
            value={""}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label htmlFor="temporaryState">State</label>
          <input
            id="temporaryState"
            type="text"
            className="form-control"
            placeholder="State"
            name="temporaryAddress.state"
            value={""}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label htmlFor="temporaryPincode">Pincode</label>
          <input
            id="temporaryPincode"
            type="text"
            className="form-control"
            placeholder="Pincode"
            name="temporaryAddress.pincode"
            value={""}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* Authorized Representative Details */}
      {/* <h4 className="mb-3">Authorized Representative Details</h4> */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="authorizedRepName" className="form-label">
            Authorized Representative Name
          </label>
          <input
            type="text"
            className="form-control"
            id="authorizedRepName"
            name="authorizedRepName"
            value={formData.authorizedRepName}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="authorizedRepPosition" className="form-label">
            Position / Role
          </label>
          <input
            type="text"
            className="form-control"
            id="authorizedRepPosition"
            name="authorizedRepPosition"
            value={formData.authorizedRepPosition}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="contactNumber" className="form-label">
            Contact Number
          </label>
          <input
            type="tel"
            className="form-control"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Contract Details */}
      {/* <h4 className="mb-3">Contract Details</h4> */}
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="contractStartDate" className="form-label">
            Contract Start Date
          </label>
          <input
            type="date"
            className="form-control"
            id="contractStartDate"
            name="contractStartDate"
            value={formData.contractStartDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="contractEndDate" className="form-label">
            Contract End Date
          </label>
          <input
            type="date"
            className="form-control"
            id="contractEndDate"
            name="contractEndDate"
            value={formData.contractEndDate}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={onBack}>
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}

function AddVehicleEntry() {
  const [activeTab, setActiveTab] = useState(0);
  const [ownership, setOwnership] = useState("Owned");

  return (
    <div className="container">
      <h2 className="h2 font-weight-bolder text-white m-4">Add Location</h2>
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="card shadow-sm">
            <div className="card-header text-white">
              <h4 className="mb-0">Add Location Details</h4>
            </div>
            <ul className="nav nav-tabs mx-4">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => setActiveTab(0)}
                >
                  Vehicle Entry
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => setActiveTab(1)}
                >
                  {ownership === "Owned"
                    ? "Documentation"
                    : "Contractor Details"}
                </button>
              </li>
              {ownership === "Owned" && ( // Only show the "Details" tab if ownership is "Owned"
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => setActiveTab(2)}
                  >
                    Details
                  </button>
                </li>
              )}
            </ul>
            <div className="tab-content mt-3">
              {activeTab === 0 && (
                <VehicleEntryForm
                  onOwnershipChange={setOwnership}
                  onSubmit={(data) => {
                    console.log("Vehicle Data: ", data);
                    setActiveTab(1);
                  }}
                />
              )}
              {activeTab === 1 &&
                (ownership === "Owned" ? (
                  <DocumentationForm
                    onBack={() => setActiveTab(0)}
                    onSubmit={(data) => {
                      console.log("Documentation Data: ", data);
                      setActiveTab(2);
                    }}
                  />
                ) : (
                  <ContractorDetailsForm
                    onBack={() => setActiveTab(0)}
                    onSubmit={(data) => {
                      console.log("Contractor Data: ", data);
                      alert("Vehicle Entry Completed!");
                    }}
                  />
                ))}
              {activeTab === 2 &&
                ownership === "Owned" && ( // Only render "DetailsForm" if ownership is "Owned"
                  <DetailsForm
                    onBack={() => setActiveTab(1)}
                    onSubmit={(data) => {
                      console.log("Details Data: ", data);
                      alert("Vehicle Entry Completed!");
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVehicleEntry;
