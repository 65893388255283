import React, { useState } from "react";
import Genetable from "../../../layouts/Genetable/Genetable";
import { Link } from "react-router-dom";

function Promotion() {
  // Sample student data
  const students = [
    {
      Student_name: "John Doe",
      email: "john@domain.com",
      role: "Student",
      organization: "Computer Science",
      status: "Active",
      employedDate: "2024-01-10",
      imgSrc: "../assets/img/team-1.jpg",
    },
    {
      Student_name: "Jane Smith",
      email: "jane@domain.com",
      role: "Student",
      organization: "Electronics",
      status: "Inactive",
      employedDate: "2024-02-14",
      imgSrc: "../assets/img/team-2.jpg",
    },
    // Additional students can be added here
  ];

  // Column definitions for Genetable
  const column_table = [
    { display_name: 'Name', column: 'Student_name' },
    { display_name: 'Email', column: 'email' },
    { display_name: 'Role', column: 'role' },
    { display_name: 'Organization', column: 'organization' },
    { display_name: 'Status', column: 'status' },
    { display_name: 'Employed Date', column: 'employedDate' }
  ];

  return (
    <div className="container-fluid py-6">
      <div className="d-flex justify-content-between align-items-center mb-2 px-4">
        <h2 className="h2 font-weight-bolder text-white mb-0">Student Promotion / Transfer</h2>
        <Link to="/adm/Master/Student/Add-Promotion">
          <button className="btn btn-light text-dark">
            Add 
          </button>
        </Link>
      </div>

      <Genetable
        data={students}
        filterEnabled={true}
        filterDateColumn={false}
        column={column_table}
        Title={"Promotion"}
      />
    </div>
  );
}

export default Promotion;
