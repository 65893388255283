import React, { useState } from "react";

// Reusable component for form fields
const EducationField = ({ label, placeholder }) => (
  <div className="col-md-6 mb-3">
    <label className="form-control-label">{label}</label>
    <input type="text" className="form-control" placeholder={placeholder} />
  </div>
);

function PreviousEducationInformation() {
  const [qualificationLevel, setQualificationLevel] = useState("");

  const handleQualificationChange = (event) => {
    setQualificationLevel(event.target.value);
  };

  // Helper function to render 10th and 12th fields
  const renderBasicEducationFields = (type) => (
    <>
      <h6>{type} Information</h6>
      <EducationField label="School Name" placeholder="Enter school name" />
      <EducationField label="Year of Passing" placeholder="Year of Passing" />
      <EducationField label="Board" placeholder="Board (e.g., CBSE, State Board)" />
      <EducationField label="Medium" placeholder="Medium (e.g., English, Tamil)" />
      <EducationField label="Total Marks" placeholder="Total Marks" />
      <EducationField label="Percentage" placeholder="Percentage" />
    </>
  );

  // Helper function to render diploma fields
  const renderDiplomaFields = () => (
    <>
    {renderBasicEducationFields("10th")} 
      <h6>Diploma Information</h6>
      <EducationField label="College Name" placeholder="Diploma College Name" />
      <EducationField label="Department" placeholder="Diploma Department" />
      <EducationField label="Year of Passing" placeholder="Year of Passing" />
      <EducationField label="Percentage" placeholder="Percentage" />
      <EducationField label="CGPA" placeholder="CGPA" />
    </>
  );

  // Helper function to render undergraduate fields
  const renderUndergraduateFields = () => (
    <>
      {renderBasicEducationFields("10th")}
      {renderBasicEducationFields("12th")}
      <h6>Undergraduate Information</h6>
      <EducationField label="University Name" placeholder="University Name" />
      <EducationField label="College Name" placeholder="College Name" />
      <EducationField label="Degree" placeholder="Degree" />
      <EducationField label="Department" placeholder="Department" />
      <EducationField label="Year of Passing" placeholder="Year of Passing" />
      <EducationField label="Percentage" placeholder="Percentage" />
      <EducationField label="CGPA" placeholder="CGPA" />
    </>
  );

  // Helper function to render postgraduate fields
  const renderPostgraduateFields = () => (
    <>
      {/* {renderBasicEducationFields("10th")} */}
      {/* {renderBasicEducationFields("12th")} */}
      {renderUndergraduateFields()}
      <h6>Postgraduate Information</h6>
      <EducationField label="University Name" placeholder="University Name" />
      <EducationField label="College Name" placeholder="College Name" />
      <EducationField label="Degree" placeholder="Degree" />
      <EducationField label="Department" placeholder="Department" />
      <EducationField label="Year of Passing" placeholder="Year of Passing" />
      <EducationField label="Percentage" placeholder="Percentage" />
      <EducationField label="CGPA" placeholder="CGPA" />
    </>
  );

  return (
    <div className="card mb-4">
      <div className="card-header pb-0">
        <h6>Previous Education Information</h6>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-control-label">Qualification Level</label>
            <select
              className="form-control"
              value={qualificationLevel}
              onChange={handleQualificationChange}
            >
              <option value="">Select Qualification Level</option>
              <option value="10th">10th</option>
              <option value="12th">12th</option>
              <option value="Diploma">Diploma</option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Postgraduate">Postgraduate</option>
            </select>
          </div>

          {qualificationLevel === "10th" && renderBasicEducationFields("10th")}
          {qualificationLevel === "12th" && (
            <>
              {renderBasicEducationFields("10th")}
              {renderBasicEducationFields("12th")}
            </>
          )}
          {qualificationLevel === "Diploma" && renderDiplomaFields()}
          {qualificationLevel === "Undergraduate" && renderUndergraduateFields()}
          {qualificationLevel === "Postgraduate" && renderPostgraduateFields()}
        </div>
      </div>
    </div>
  );
}

export default PreviousEducationInformation;
