import React, { useState } from "react";

function AddTripChangeEntry({ onSubmit }) {
  const [formData, setFormData] = useState({
    trip: "",
    newDriver: "",
    newBus: "",
    reason: "",
    status: "Pending",
    isRoundTrip: false,
    returnDriver: "",
    returnBus: "",
    returnReason: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data: ", formData);
    onSubmit(formData);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="trip" className="form-label">
                Trip
              </label>
              <select
                className="form-select"
                id="trip"
                name="trip"
                value={formData.trip}
                onChange={handleChange}
                required
              >
                <option value="">Select Trip</option>
                <option value="Trip 1">Trip 1</option>
                <option value="Trip 2">Trip 2</option>
                {/* Add more trips as needed */}
              </select>
            </div>
            </div>
            <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="newDriver" className="form-label">
                New Driver
              </label>
              <select
                className="form-select"
                id="newDriver"
                name="newDriver"
                value={formData.newDriver}
                onChange={handleChange}
                required
              >
                <option value="">Select New Driver</option>
                <option value="Alice Johnson">Alice Johnson</option>
                <option value="Bob Smith">Bob Smith</option>
                {/* Add more drivers as needed */}
              </select>
            </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-6"> 
            <div className="mb-3">
              <label htmlFor="newBus" className="form-label">
                New Bus
              </label>
              <select
                className="form-select"
                id="newBus"
                name="newBus"
                value={formData.newBus}
                onChange={handleChange}
                required
              >
                <option value="">Select New Bus</option>
                <option value="TN01XY1234">TN01XY1234</option>
                <option value="TN02AB5678">TN02AB5678</option>
                {/* Add more buses as needed */}
              </select>
            </div>
          </div>

          
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="reason" className="form-label">
                Reason for Change
              </label>
              <input
                type="text"
                className="form-control"
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                required
              />
            </div>
            </div>
            </div>
           

        {/* Round Trip Toggle */}
        <div className="form-check my-4">
          <input
            type="checkbox"
            className="form-check-input"
            id="isRoundTrip"
            name="isRoundTrip"
            checked={formData.isRoundTrip}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="isRoundTrip">
            Is this a round trip?
          </label>
        </div>

        {/* Round Trip Details */}
        {formData.isRoundTrip && (
          <>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="returnDriver" className="form-label">
                  Return Driver
                </label>
                <select
                  className="form-select"
                  id="returnDriver"
                  name="returnDriver"
                  value={formData.returnDriver}
                  onChange={handleChange}
                >
                  <option value="">Select Return Driver</option>
                  <option value="Alice Johnson">Alice Johnson</option>
                  <option value="Bob Smith">Bob Smith</option>
                  {/* Add more drivers as needed */}
                </select>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="returnBus" className="form-label">
                  Return Bus
                </label>
                <select
                  className="form-select"
                  id="returnBus"
                  name="returnBus"
                  value={formData.returnBus}
                  onChange={handleChange}
                >
                  <option value="">Select Return Bus</option>
                  <option value="TN01XY1234">TN01XY1234</option>
                  <option value="TN02AB5678">TN02AB5678</option>
                  {/* Add more buses as needed */}
                </select>
              </div>
            </div>
            </div>
            
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="returnReason" className="form-label">
                  Reason for Return Trip Change
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="returnReason"
                  name="returnReason"
                  value={formData.returnReason}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
          
        )}
         <div className="row">
            <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            </div>
            <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="Description" className="form-label">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="Description"
                name="Description"
                value={formData.Description || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          </div>
        

        <div className="text-center">
          <button type="submit" className="btn btn-primary mt-4">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTripChangeEntry;
