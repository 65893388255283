import React, { useState } from "react";
import "./AddLocationSelector.css";

function AddLocationSelector({ onSubmit }) {
  const [formData, setFormData] = useState({
    location: "",
    startPoint: "",
    endPoint: "",
    stages: [""],
    description: "",
    status: "",
  });
  const [stageInput, setStageInput] = useState("");
  const [stages, setStages] = useState([]);

  // Add a new stage
  const handleAddStage = (e) => {
    if (e.key === "Enter" && stageInput.trim() !== "") {
      e.preventDefault(); // Prevent form submission
      setStages([...stages, stageInput.trim()]);
      setStageInput("");
    }
  };

  // Remove a stage
  const handleRemoveStage = (index) => {
    setStages(stages.filter((_, i) => i !== index));
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle stage changes
  const handleStageChange = (index, value) => {
    const updatedStages = [...formData.stages];
    updatedStages[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      stages: updatedStages,
    }));
  };

  const addStage = () => {
    setFormData((prevData) => ({
      ...prevData,
      stages: [...prevData.stages, ""],
    }));
  };

  const removeStage = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      stages: prevData.stages.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    onSubmit(formData);
  };

  return (
    <div className="container-fluid">
      <h2 className="h2 font-weight-bolder text-white m-4">Add Registration</h2>
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="card shadow-sm">
            <div className="card-header text-white">
              <h4 className="mb-0">Add Location Details</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="startPoint" className="form-label">
                      Start Point
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="startPoint"
                      name="startPoint"
                      value={formData.startPoint}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="endPoint" className="form-label">
                      End Point
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="endPoint"
                      name="endPoint"
                      value={formData.endPoint}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="stages" className="form-label">
                      Stages
                    </label>

                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Add a stage and press Enter"
                      value={stageInput}
                      onChange={(e) => setStageInput(e.target.value)}
                      onKeyDown={handleAddStage}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="tags-input mt-4">
                      {stages.map((stage, index) => (
                        <div key={index} className="tag">
                          {stage}
                          <button
                            type="button"
                            className="btn-close ms-2"
                            onClick={() => handleRemoveStage(index)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      id="status"
                      className="form-select"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLocationSelector;
