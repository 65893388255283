import React, { useState } from "react";
import "../../AddRegistration/AddRegistration.css";

const PublicationAndSubjects = () => {
  const [publications, setPublications] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [inputSubject, setInputSubject] = useState("");

  // Handle change for publication table
  const handlePublicationChange = (id, field, value) => {
    const updatedPublications = publications.map((pub) =>
      pub.id === id ? { ...pub, [field]: value } : pub
    );
    setPublications(updatedPublications);
  };

  // Add a new publication row
  const addPublicationRow = () => {
    const newPublication = { id: Date.now(), authorId: "", authorLink: "" };
    setPublications([...publications, newPublication]);
  };

  // Remove a publication row
  const removePublicationRow = (id) => {
    setPublications(publications.filter((pub) => pub.id !== id));
  };

  // Add a subject (tag)
  const handleAddSubject = (e) => {
    if (e.key === "Enter" && inputSubject.trim()) {
      e.preventDefault(); // Prevent default behavior of Enter key
      if (!subjects.includes(inputSubject.trim())) {
        setSubjects([...subjects, inputSubject.trim()]);
        setInputSubject("");
      }
    }
  };

  // Remove a subject (tag)
  const handleRemoveSubject = (indexToRemove) => {
    setSubjects(subjects.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="form-scroll-container">
      <div className="publication-details">
        <h3 className="text-center">Publication Details</h3>
        <table className="table table-bordered language-table">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Author ID</th>
              <th scope="col">Author Link</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {publications.map((pub, index) => (
              <tr key={pub.id}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={pub.authorId}
                    onChange={(e) =>
                      handlePublicationChange(pub.id, "authorId", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={pub.authorLink}
                    onChange={(e) =>
                      handlePublicationChange(pub.id, "authorLink", e.target.value)
                    }
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removePublicationRow(pub.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn btn-primary" onClick={addPublicationRow}>
          + 
        </button>
      </div>

      <div className="technical-skills mt-4">
        <h3 className="text-center">Subjects Handled</h3>
        <input
          type="text"
          placeholder="Add a subject and press Enter"
          value={inputSubject}
          onChange={(e) => setInputSubject(e.target.value)}
          onKeyDown={handleAddSubject}
          className="form-control mb-4 skill-input"
        />
        <div className="tags-input">
          {subjects.map((subject, index) => (
            <div key={index} className="tag">
              {subject}
              <button
                type="button"
                className="btn-close ms-2"
                onClick={() => handleRemoveSubject(index)}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicationAndSubjects;
