import React, { useState } from "react";
import userAvatar from "../../../../../assets/images/avatar/7.png";
import "./AddDriverDetails.css";

function AddDriverDetails({ onSubmit }) {
  const [formData, setFormData] = useState({
    driverID: "",
    fullName: "",
    dateOfBirth: "",
    photo: "",
    mobileNumber: "",
    alternateContactNumber: "",
    emailAddress: "",
    permanentAddress: "",
    emergencyContact: "",
    licenseNumber: "",
    licenseType: "",
    licenseExpiryDate: "",
    issuingAuthority: "",
    employmentStartDate: "",
    employmentStatus: "Active",
    workSchedule: "",
    experience: "",
    policeVerificationStatus: "Not Verified",
    trainingCertification: "",
    medicalFitness: "",
    panAadharNumber: "",
    languagesKnown: "",
    bloodGroup: "",
    bankAccountDetails: "",
    description: "",
  });

  // State to manage the avatar image
  const [avatar, setAvatar] = useState(userAvatar);

  // Handle profile upload
  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatar(e.target.result); // Update avatar with uploaded image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    onSubmit(formData);
  };

  // State for languages and input
  const [languages, setLanguages] = useState([]);
  const [languageInput, setLanguageInput] = useState("");

  // Function to add a new language
  const handleAddLanguage = (e) => {
    if (e.key === "Enter" && languageInput.trim() !== "") {
      e.preventDefault(); // Prevent form submission
      setLanguages((prev) => [...prev, languageInput.trim()]);
      setLanguageInput(""); // Clear the input
    }
  };

  // Function to remove a language
  const handleRemoveLanguage = (index) => {
    setLanguages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="container">
      <h2 className="h2 font-weight-bolder text-white m-4">
        Add Driver Details
      </h2>
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="card shadow-sm">
            <div className="card-header text-white d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Driver Details Form</h4>
              <img
                src={avatar}
                alt="user-avatar"
                className="rounded border"
                height="80"
                width="60"
              />
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="driverID" className="form-label">
                        Driver ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="driverID"
                        name="driverID"
                        value={formData.driverID}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="photo" className="form-label">
                        Photo
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="photo"
                        name="photo"
                        accept="image/*" // Restricts to images only
                        onChange={handleProfileUpload}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="emailAddress" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="licenseNumber" className="form-label">
                        License Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="licenseNumber"
                        name="licenseNumber"
                        value={formData.licenseNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="licenseType" className="form-label">
                        License Type
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="licenseType"
                        name="licenseType"
                        value={formData.licenseType}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="licenseExpiryDate" className="form-label">
                        License Expiry Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="licenseExpiryDate"
                        name="licenseExpiryDate"
                        value={formData.licenseExpiryDate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="employmentStartDate"
                        className="form-label"
                      >
                        Employment Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="employmentStartDate"
                        name="employmentStartDate"
                        value={formData.employmentStartDate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="mobileNumber" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="alternateContactNumber"
                        className="form-label"
                      >
                        Alternate Contact Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="alternateContactNumber"
                        name="alternateContactNumber"
                        value={formData.alternateContactNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label htmlFor="temporaryAddressLine1">Address Line 1</label>
                  <input
                    id="temporaryAddressLine1"
                    type="text"
                    className="form-control"
                    placeholder="Address Line 1"
                    name="temporaryAddress.line1"
                    value={""}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <label htmlFor="temporaryCity">City</label>
                    <input
                      id="temporaryCity"
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="temporaryAddress.city"
                      value={""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <label htmlFor="temporaryState">State</label>
                    <input
                      id="temporaryState"
                      type="text"
                      className="form-control"
                      placeholder="State"
                      name="temporaryAddress.state"
                      value={""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <label htmlFor="temporaryPincode">Pincode</label>
                    <input
                      id="temporaryPincode"
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      name="temporaryAddress.pincode"
                      value={""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="languagesKnown" className="form-label">
                      Languages Known
                    </label>

                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Add a language and press Enter"
                      value={languageInput}
                      onChange={(e) => setLanguageInput(e.target.value)}
                      onKeyDown={handleAddLanguage}
                    />
                    </div>
                    <div className="col-md-6">

                    <div className="tags-input mt-4">
                      {languages.map((language, index) => (
                        <div key={index} className="tag">
                          {language}
                          <button
                            type="button"
                            className="btn-close ms-2"
                            onClick={() => handleRemoveLanguage(index)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="workSchedule" className="form-label">
                        Work Schedule
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="workSchedule"
                        name="workSchedule"
                        value={formData.workSchedule}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="panAadharNumber" className="form-label">
                        PAN / Aadhar Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="panAadharNumber"
                        name="panAadharNumber"
                        value={formData.panAadharNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="employmentStatus" className="form-label">
                        Status
                      </label>
                      <select
                        className="form-control"
                        id="employmentStatus"
                        name="employmentStatus"
                        value={formData.employmentStatus}
                        onChange={handleChange}
                        required
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Description" className="form-label">
                        Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Description"
                        name="Description"
                        value={formData.Description}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDriverDetails;
