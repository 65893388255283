import React, { useState } from "react";

function AcademicInformation() {
  const [formData, setFormData] = useState({
    department: "",
    academicYear: "",
    year: "",
    admissionDate: "",
    studentStatus: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card">
            <div className="card-header">
              <h4>Academic Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Educational Type</label>
                  <select className="form-select">
                    <option>Select Educational Type</option>
                    <option>Undergraduate</option>
                    <option>Postgraduate</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-control-label">Degree</label>
                  <select className="form-select">
                    <option>Select Degree</option>
                    <option>Bachelor's</option>
                    <option>Master's</option>
                    <option>Ph.D.</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="department" className="form-label">
                      Department
                    </label>
                    <select
                      className="form-select"
                      id="department"
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Department</option>
                      <option value="computer-science">Computer Science</option>
                      <option value="mechanical">Mechanical</option>
                      <option value="civil">Civil</option>
                      <option value="electrical">Electrical</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="academicYear" className="form-label">
                      Academic Year (Batch)
                    </label>
                    <select
                      className="form-select"
                      id="academicYear"
                      name="academicYear"
                      value={formData.academicYear}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Academic Year</option>
                      <option value="2023-2024">2023-2024</option>
                      <option value="2024-2025">2024-2025</option>
                      <option value="2025-2026">2025-2026</option>
                      <option value="2026-2027">2026-2027</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="year" className="form-label">
                      Year
                    </label>
                    <select
                      className="form-select"
                      id="year"
                      name="year"
                      value={formData.year}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Year</option>
                      <option value="1">1st Year</option>
                      <option value="2">2nd Year</option>
                      <option value="3">3rd Year</option>
                      <option value="4">4th Year</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="studentSection"
                    className="form-control-label"
                  >
                    Section
                  </label>
                  <select
                    className="form-select"
                    id="studentSection"
                    name="studentSection"
                    value={formData.studentSection}
                    onChange={handleChange}
                  >
                    <option value="">Select Section</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="admissionDate" className="form-label">
                      Date of Admission
                    </label>
                    <input
                      type="datetime-local"
                      className="form-select"
                      id="admissionDate"
                      name="admissionDate"
                      value={formData.admissionDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="studentStatus" className="form-control-label">
                    Student Status
                  </label>
                  <select
                    className="form-select"
                    id="studentStatus"
                    name="studentStatus"
                    value={formData.studentStatus}
                    onChange={handleChange}
                  >
                    <option value="">Select Student Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademicInformation;
