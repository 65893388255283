import React from "react";
import "./Modal.css"; // Add custom styles for the modal here

function Modal({ show, data, onClose }) {
    if (!show) return null; // Don't render the modal if show is false

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="btn btn-close" onClick={onClose}>
                    <i className="fa-solid fa-xmark"></i> {/* You can use a close icon */}
                </button>
                <h5 className="modal-title">Details</h5>
                <div className="modal-body">
                    {Object.keys(data).map((key, index) => (
                        <div key={index} className="modal-item">
                            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
                            <span className="modal-value">{data[key]}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Modal;
