import React, { useState } from 'react';
import userAvatar from '../../../../../../assets/images/avatar/7.png';

function PersonalDetails() {
    // State to manage the avatar image
    const [avatar, setAvatar] = useState(userAvatar);

    // Handle profile upload
    const handleProfileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setAvatar(e.target.result); // Update avatar with uploaded image
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="container-fluid py-4">
            
            <div className="row">
                <div className="col-lg-12 mx-auto ">
                    <div className="card shadow-sm">
                        <div className="card-header  text-white d-flex justify-content-between align-items-center">
                            <h4 className="mb-0">Personal Details</h4>
                            <img
                                src={avatar}
                                alt="user-avatar"
                                className="rounded border"
                                height="80"
                                width="60"
                            />
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label htmlFor="studentId" className="form-label">
                                            Student ID
                                        </label>
                                        <input
                                            type="text"
                                            id="studentId"
                                            className="form-control"
                                            placeholder="Enter student ID"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="profileUpload" className="form-label">
                                            Upload Profile
                                        </label>
                                        <input
                                            type="file"
                                            id="profileUpload"
                                            className="form-control"
                                            onChange={handleProfileUpload}
                                            accept="image/*" // Restricts to images only
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="firstName" className="form-label">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className="form-control"
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="lastName" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className="form-control"
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="dob" className="form-label">
                                            Date of Birth
                                        </label>
                                        <input type="date" id="dob" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="gender" className="form-label">
                                            Gender
                                        </label>
                                        <select id="gender" className="form-select">
                                            <option>Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bloodGroup" className="form-label">
                                            Blood Group
                                        </label>
                                        <select id="bloodGroup" className="form-select">
                                            <option>Select Blood Group</option>
                                            <option>A+</option>
                                            <option>A-</option>
                                            <option>B+</option>
                                            <option>B-</option>
                                            <option>O+</option>
                                            <option>O-</option>
                                            <option>AB+</option>
                                            <option>AB-</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="nationality" className="form-label">
                                            Nationality
                                        </label>
                                        <input
                                            type="text"
                                            id="nationality"
                                            className="form-control"
                                            placeholder="Nationality"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="religion" className="form-label">
                                            Religion
                                        </label>
                                        <input
                                            type="text"
                                            id="religion"
                                            className="form-control"
                                            placeholder="Religion"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="caste" className="form-label">
                                            Caste
                                        </label>
                                        <input
                                            type="text"
                                            id="caste"
                                            className="form-control"
                                            placeholder="Caste"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="aadharNumber" className="form-label">
                                            Aadhar Number
                                        </label>
                                        <input
                                            type="text"
                                            id="aadharNumber"
                                            className="form-control"
                                            placeholder="Aadhar Number"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="residentialType" className="form-label">
                                            Residential Type
                                        </label>
                                        <select id="residentialType" className="form-select">
                                            <option>Select Residential Type</option>
                                            <option>Hostel</option>
                                            <option>Dayscholar</option>
                                        </select>
                                    </div>
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalDetails;
